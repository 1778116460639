import { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { useRippleNotification } from '@/design';
import * as api from '@/services/common';
import isInIframe from '@/utils/isInIframe';

const CHECK_NEW_VERSION_TIME_MS = 86400000;

const NewVersionToast = ({ checkIsInIframe = isInIframe }) => {
  const { t } = useTranslation(['common']);
  const toast = useToast();
  const { isNotificationActive, openNotification } = useRippleNotification();
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  useEffect(() => {
    if (checkIsInIframe()) {
      return;
    }

    api.getNewVersion().then((data) => {
      setCurrentVersion(data.version);
    });
  }, [checkIsInIframe]);

  useEffect(() => {
    if (checkIsInIframe()) {
      return;
    }

    const listener = setInterval(() => {
      api.getNewVersion().then((data) => {
        if (data.version && currentVersion && data.version !== currentVersion) {
          setCurrentVersion(data.version);

          const toastId = 'NewVersionToast';
          const title = t('common:newVersionAvailable');

          if (!isNotificationActive(toastId)) {
            openNotification({
              id: toastId,
              variant: 'info',
              title,
            });
          }
        }
      });
    }, CHECK_NEW_VERSION_TIME_MS);

    return () => {
      clearInterval(listener);
    };
  }, [t, toast, isNotificationActive, currentVersion, checkIsInIframe, openNotification]);

  return null;
};

export default NewVersionToast;
