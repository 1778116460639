import { Box, Flex, ModalOverlayProps, Stack } from '@chakra-ui/react';

import { RippleDrawer, RippleDrawerBody, RippleDrawerContent, RippleDrawerFooter, RippleDrawerOverlay } from '../RippleDrawer';
import { RippleClose } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleSheetProps } from '../RippleSheet/RippleSheet';
import { RippleBodyText02, RippleHeading04 } from '../RippleText';

export type RippleSheetModalProps = RippleSheetProps & {
  isOpen: boolean;
  onClose: () => void;
  subtitle?: string;
  footer?: React.ReactNode;
  overlayProps?: ModalOverlayProps;
};

export function RippleSheetModal({
  children,
  title,
  subtitle,
  description,
  contentWidth = '1140px',
  overlayProps = { bg: 'dark.100', opacity: '0.5 !important' },
  isOpen,
  isDisabled = false,
  onClose,
  footer,
}: RippleSheetModalProps): React.JSX.Element {
  return (
    <RippleDrawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <RippleDrawerOverlay {...overlayProps} />
      <RippleDrawerContent minW="350px" height="calc(100% - 24px)" borderRadius="12px" borderBottomRadius="0">
        <RippleDrawerBody overflow="hidden" display="flex" padding="0">
          <Box w="100vw" overflowY="auto" display="flex" justifyContent="center">
            <Flex justifyContent="center" w={['100%', '100%', contentWidth]}>
              <Flex mx="16px" w="100%" h="100%" direction="column">
                <Stack spacing="8px" my="32px">
                  {subtitle && <RippleBodyText02 color="neutral.300">{subtitle}</RippleBodyText02>}
                  <Flex direction="row" justifyContent="space-between" alignItems="center">
                    <RippleHeading04>{title}</RippleHeading04>
                    <RippleIconButton aria-label="close" icon={<RippleClose />} onClick={onClose} isDisabled={isDisabled} />
                  </Flex>
                  {description && <RippleBodyText02 color="neutral.300">{description}</RippleBodyText02>}
                </Stack>
                <Box flex="1">{children}</Box>
              </Flex>
            </Flex>
          </Box>
        </RippleDrawerBody>
        {footer && (
          <RippleDrawerFooter>
            <Box maxWidth={contentWidth} px="16px" width="100%" margin="auto">
              {footer}
            </Box>
          </RippleDrawerFooter>
        )}
      </RippleDrawerContent>
    </RippleDrawer>
  );
}
