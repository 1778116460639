import { getRegion } from '@/utils/region';

import { ALWAYS, DEVELOPMENT_ONLY, GLOBAL_ONLY, LOCAL_ONLY, NOT_YET, SERVER_ENV } from './condition';
import { ASSETS_CDN_MAPPING, HOST_MAPPING } from './constants';
import { featureToggleControl } from './featureToggleControl';
import { createParameterControl } from './parameterControl';

const REGION = getRegion();

export const FT_WEB_CLIENT_SUPPORT_SAFARI = LOCAL_ONLY || DEVELOPMENT_ONLY;
export const FT_WEB_CLIENT_DISABLED_BEFORE_RELOAD_ALERT = LOCAL_ONLY || DEVELOPMENT_ONLY;

export const featureControl = featureToggleControl({
  newSettingsPage: LOCAL_ONLY,
  newTeamSettingsFailoverForTesting: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfFileTransfer: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfCopyPaster: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__sso__idpType__splashtopSecureOverlay: LOCAL_ONLY || DEVELOPMENT_ONLY,
  CF_288__unboxing_for_empty_computer_or_client_list: GLOBAL_ONLY,
  CF_355__computer_list_UI_renewal__phase_2: NOT_YET,
  CF_365_sos_invitation: ALWAYS,
  CF_346__computer_deletion_custom_input: ALWAYS,
  CF_416__adjustment_on_support_session_for_support_form: ALWAYS,
  PCP_6__dashboard__show_reboot_required: LOCAL_ONLY,
  CF_323__support_form_list: ALWAYS,
  CF_434__inventory_performance: ALWAYS,
  CF_439_sos_package_direct_connect: ALWAYS,
  /** Remove those two toggle together, PAGE_antivirus_threat_logs__redirect is for QA testing before antivirus release */
  PAGE_antivirus_threat_logs: ALWAYS,
  PAGE_antivirus_threat_logs__redirect: false,
  /** Remove those two toggle together end */
  PCP_541__Discover_Pages: ALWAYS,
  rename_to_system_tools: ALWAYS,
  PCP_1105__TeamSettings__SSO_Ease_JIT_rule: ALWAYS,
  CF_481_sos_package_disclaimer_URL: ALWAYS,
  PCP_734__Dashboard_show_ssh_computers: ALWAYS,
  CLE_53__Computer_Device_Icon_Change: NOT_YET, // NOTE: Need to wait for SRC app to implement
  CF_383__SOS_Package_ViewerModal: ALWAYS,
  CLE_7__NEW_ENDPOINT_SECURITY: ALWAYS,
  CF_478__SupportSessionLogs_SessionTabs: ALWAYS,
  PCP_533__Active_SSH_Sessions: ALWAYS,
  PCP_556__Dashboard_hide_show_widget: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_733__Transcript_Chat_TextLimit_enlarge: ALWAYS,
  PCP_460__User_Management: LOCAL_ONLY || DEVELOPMENT_ONLY,
  User_Management_Granular_Control_For_Session_Recording: false,
  PCP_740__ComputerList__SRS_TokenMethodForFetchingData: ALWAYS,
  PCP_520__ServiceDeskLogs__SystemToolsSession: ALWAYS,
  PCP_742__Dashboard_M_M_navigation_design_improvement: ALWAYS,
  PCP_1000__Antivirus_Threat_Logs_Management: ALWAYS,
  PCP_1137_Refactor_Web_Client: LOCAL_ONLY || DEVELOPMENT_ONLY,
  // Policy start
  PCP_1134__Policy_management: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1391__Policy_software_patch: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1682__Policy_os_patch: LOCAL_ONLY || DEVELOPMENT_ONLY,
  // Policy end
  PCP_1250__SystemToolsSession__DetailsIcon: ALWAYS,
  BT_14454__ComputerList_UseNewVersionRebootComputerAPI: ALWAYS,
  PCP_1320__Get_rid_of_html_table_element: LOCAL_ONLY || DEVELOPMENT_ONLY,
  BT_14455__ComputerList_RemoteWake_UseNewWebAPI: ALWAYS,
  PCP_545__SOS_CALL_ANDROID_LAUNCHER: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_747__TeamSettings_SessionRecordingEnhancements: ALWAYS,
  PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert: ALWAYS,
  PCP_1465__CSV_INJECTION: ALWAYS,
  PCP_1391__Software_update: LOCAL_ONLY,
  PCP_1502__AllComputers__SearchFromQuery: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_343__Dashboard_better_os_overview: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1440__Accept_version_in_3_digit: ALWAYS,
  PCP_1537__EndpointSecurity__ComputerListAPI_TokenMethod: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1336__Manage_Group_Associates: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1380__TeamSettings_EndpointVersionControl: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_499__TeamSettings_ConcurrentSessionStringChange: ALWAYS,
  PCP_1430__Add_Status_Filter_On_Endpoint_Security: ALWAYS,
  PCP_1756__RippleInlineSelect_refactor: ALWAYS,
  PCP_1551__ComputerList_AccessPermission_InviteTeamMemberFix: ALWAYS,
  PCP_458__TeamSettings_EmailDeviceAuthentication_AllowSuperAdmin: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1190__TeamSettings_PasswordRotation: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_763__Session_log__Expiry_team: ALWAYS,
  PCP_1577__Grouping_ManageAssociates: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_433__Toolbar_Monitor_and_Console_Button: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_631__Inventory_OS_Columns: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1414__PasswordExpirationAlert: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1850_Zendesk_Widget_Instrumentality: ALWAYS,
  PCP_1957__RippleSelect_refactor: ALWAYS,
  PCP_2010__Remove_QuitTeamSentence: ALWAYS,
  PCP_1816__AllComputers__PlatformFilter: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2039__AllComputers__AdjustChunkForDeleteComputers: ALWAYS,
  PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment: ALWAYS,
  PCP_2135__Zendesk_show_hide: ALWAYS,
  PCP_1713__GetStartedPage: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2201__TeamSettings__TeamControl_refactor: false,
  PCP_1886__AntivirusSummaryExport: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2003__TeamSettings__SettingControlContext_refactor: false,
  PCP_2261__JotaiAtomStateIsolation: LOCAL_ONLY || DEVELOPMENT_ONLY,
});

export const parameterControl = createParameterControl({
  logServiceUrl: process.env.NEXT_PUBLIC_LOG_SERVICE_URL as string,
  fontDomain: `${ASSETS_CDN_MAPPING[REGION][SERVER_ENV] || ASSETS_CDN_MAPPING.global.production}/fonts`,
  host: `${HOST_MAPPING[REGION][SERVER_ENV] || HOST_MAPPING.global.production}`,
});
