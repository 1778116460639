import React, { useEffect, useRef, useState } from 'react';

import { Box, CSSObject } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import { RippleClose16, RippleSearch } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleInput } from '../RippleInput';

export type RippleSearchBarProps = {
  sx?: CSSObject;
  placeholder?: string;
  initKeyword?: string;
  onSearch: (keyword: string) => void;
};

export const RippleSearchBar = ({ placeholder, initKeyword, onSearch, sx }: RippleSearchBarProps) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>(initKeyword ?? '');

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === 'Slash') {
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keyup', handler);

    return () => {
      window.removeEventListener('keyup', handler);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
    onSearch(event.target.value);
  };

  const handleInputClear = () => {
    setSearchKeyword('');
    onSearch('');
  };

  const label = t('common:table.search');

  return (
    <Box sx={sx} position="relative" width="256px">
      <RippleInput
        ref={inputRef}
        data-testid="search-input"
        borderRadius="4px"
        value={searchKeyword || ''}
        placeholder={placeholder ?? label}
        height="32px"
        py="0"
        color="dark.90"
        fontSize="0.875rem"
        borderColor="neutral.300"
        focusBorderColor="blue.100"
        onChange={handleInputChange}
        backgroundColor="white"
        pl="32px"
      />
      <Box zIndex={2} position="absolute" top="50%" left="4px" transform="translateY(-50%)">
        <RippleSearch />
      </Box>
      {searchKeyword && (
        <RippleIconButton
          aria-label="clear"
          data-testid="close-search-btn"
          zIndex={2}
          p="1px"
          width="18px"
          height="18px"
          position="absolute"
          top="50%"
          right="8px"
          transform="translateY(-50%)"
          borderRadius="50%"
          backgroundColor="neutral.80"
          _hover={{
            backgroundColor: 'neutral.300',
          }}
          onClick={handleInputClear}
          icon={<RippleClose16 color="white" />}
        />
      )}
    </Box>
  );
};
