import React, { forwardRef as reactFrowardRef, useImperativeHandle, useMemo } from 'react';

import { FlexProps, forwardRef } from '@chakra-ui/react';
import { ColumnDef, Row, Table, TableOptions, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ValueIteratee } from 'lodash';
import groupBy from 'lodash/groupBy';
import { TableVirtuoso, TableVirtuosoProps } from 'react-virtuoso';

import { RippleTBody, RippleTD, RippleTH, RippleTHead, RippleTR, RippleTable, RippleTableGroupHead, RippleTableProps } from './RippleTable';
import { FillerRow } from './VirtuosoComponents';

type VirtuosoOptions<T> = Omit<TableVirtuosoProps<[string, Array<Row<T>>], Table<T>>, 'data' | 'width' | 'height'>;

/**
 * `RippleVirtuosoReactTableProps` is a component that wraps the `@tanstack/react-table`, `react-virtuoso`, and `RippleTable` components.
 * It applies our design system and renders a table based on the provided data and columns props.
 *
 * @component
 * @param {data} props - The data to be displayed in the table.
 * @param {columns} props - The react-table's columns props.
 * @param {width} props - The width of the table.
 * @param {height} props - The height of the table.
 * @param {TableOptions} props - The properties that define the data and structure of the table. These are passed directly to the `useReactTable` hook
 * @param {TableVirtuosoProps} props - The properties that define the data and structure of the table. These are passed directly to the `TableVirtuoso` component
 * @returns {React.JSX.Element} A table element rendered with the `RippleTable` component.
 *
 * @example
 * // Data for the table
 * const data = [
 *   { firstName: 'John', lastName: 'Doe' },
 *   { firstName: 'Jane', lastName: 'Doe' },
 * ];
 *
 * // Columns for the table
 * const columns = [
 *   { Header: 'First Name', accessor: 'firstName' },
 *   { Header: 'Last Name', accessor: 'lastName' },
 * ];
 *
 * <RippleVirtuosoReactTable data={data} columns={columns} width="500px" height="500px" />
 */
type RippleGroupedVirtuosoReactTableProps<T> = RippleTableProps & {
  /** The data to be displayed in the table. */
  data: Array<T>;
  /** The react-table's columns props. */
  columns: Array<ColumnDef<T, any>>;
  /** The key to group the data by. */
  groupByFn: ValueIteratee<Row<T>>;
  /** The component to render the group header. */
  groupHeader: ({ rows }: { rows: Array<Row<T>> }) => React.JSX.Element;
  /** The component to render the group empty content. */
  groupEmptyContent: () => React.JSX.Element;
  /** The gap between the grouped row in the table. */
  gap?: FlexProps['marginTop'];
  /** The width of table */
  width?: TableVirtuosoProps<Row<T>, Table<T>>['width'];
  /** The height of table. */
  height?: TableVirtuosoProps<Row<T>, Table<T>>['height'];
  /** The options for the react-table. */
  tableOptions?: Omit<TableOptions<T>, 'getCoreRowModel' | 'data' | 'columns'>;
  /** The options for the react-virtuoso. */
  virtuosoOptions?: VirtuosoOptions<T> | ((table: Table<T>) => VirtuosoOptions<T>);
};
export const RippleGroupedVirtuosoReactTable = reactFrowardRef(function <T = unknown>(
  {
    data,
    columns,
    groupHeader,
    groupEmptyContent,
    groupByFn,
    gap = '16px',
    width = '100%',
    height = '500px',
    tableOptions = {},
    virtuosoOptions = {},
    ...tableProps
  }: Readonly<RippleGroupedVirtuosoReactTableProps<T>>,
  ref: React.Ref<{ getTable: () => Table<T> }>,
) {
  const table = useReactTable({
    ...tableOptions,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        getTable() {
          return table;
        },
      };
    },
    [table],
  );

  // TODO: handle group sorting here
  const groupedRows = useMemo(() => Object.entries(groupBy(table.getRowModel().rows, groupByFn)), [groupByFn, table]);
  const defaultItemContent = useMemo(() => renderItemContent(groupHeader, groupEmptyContent, gap), [gap, groupEmptyContent, groupHeader]);
  const defaultHeaderContent = useMemo(() => renderFixedHeaderContent(table), [table]);

  const {
    components,
    style,
    itemContent = defaultItemContent,
    fixedHeaderContent = defaultHeaderContent,
    ...otherVirtuosoProps
  } = typeof virtuosoOptions === 'function' ? virtuosoOptions(table) : virtuosoOptions;

  return (
    <TableVirtuoso
      data={groupedRows}
      fixedHeaderContent={fixedHeaderContent}
      itemContent={itemContent}
      components={{
        Table: rippleTable(tableProps),
        TableHead: RippleTHead,
        FillerRow,
        ...components,
      }}
      style={{
        ...style,
        width,
        height,
      }}
      {...otherVirtuosoProps}
    />
  );
}) as <T = unknown>(props: RippleGroupedVirtuosoReactTableProps<T>) => React.JSX.Element;

const rippleTable = (tableProps: RippleTableProps) =>
  forwardRef((props: RippleTableProps, ref) => <RippleTable {...props} ref={ref} {...tableProps} />);

function renderFixedHeaderContent<T>(table: Readonly<Table<T>>) {
  return () =>
    table.getHeaderGroups().map((headerGroup) => (
      <RippleTR key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <RippleTH
            key={header.id}
            width={`${header.column.columnDef.size}px`}
            isSortable={header.column.getCanSort()}
            sortDirection={header.column.getIsSorted()}
            onClick={header.column.getToggleSortingHandler()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </RippleTH>
        ))}
      </RippleTR>
    ));
}

function renderItemContent<T>(
  GroupHeader: ({ rows }: { rows: Array<Row<T>> }) => React.JSX.Element,
  GroupEmptyContent: () => React.JSX.Element,
  gap: FlexProps['marginTop'],
) {
  return (index: number, group: [string, Array<Row<T>>]) => {
    const [key, rows] = group;
    return (
      <RippleTBody key={key} sx={{ marginTop: index > 0 ? gap : 0 }}>
        <RippleTableGroupHead textTransform="capitalize">
          <GroupHeader rows={rows} />
        </RippleTableGroupHead>
        {rows.length === 0 ? (
          <GroupEmptyContent />
        ) : (
          // TODO: handle rows sorting here
          rows.map((row) => {
            return (
              <RippleTR key={row.id} isSelected={row.getIsSelected()}>
                {row.getVisibleCells().map((cell) => (
                  <RippleTD key={cell.id} width={`${cell.column.columnDef.size}px`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </RippleTD>
                ))}
              </RippleTR>
            );
          })
        )}
      </RippleTBody>
    );
  };
}
