import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleEndUser({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path d="M12 16V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 20H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M4 13V15.5C4 15.7761 4.22386 16 4.5 16L19.5 16C19.7761 16 20 15.7761 20 15.5V4.5C20 4.22386 19.7761 4 19.5 4L4.5 4C4.22386 4 4 4.22386 4 4.5V7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M2 10L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M11 7L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M11 13L14 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </Icon>
  );
}
