type EnvironmentConfig = {
  env: 'global' | 'eu';
  appUrl: string;
  sosDownloadLink: string;
  sosTrialDays: string;
  sosPrice: string;
};

const globalConfig: EnvironmentConfig = {
  env: 'global',
  appUrl: 'my.splashtop.com',
  sosDownloadLink: 'https://sos.splashtop.com',
  sosTrialDays: '7',
  sosPrice: '$199.00',
};

const europeConfig: EnvironmentConfig = {
  env: 'eu',
  appUrl: 'my.splashtop.eu',
  sosDownloadLink: 'https://sos.splashtop.eu',
  sosTrialDays: '14',
  sosPrice: '€189,00',
};

export type regionDataOptions = {
  currency?: string;
  currencyLocale?: string;
  sosPrice?: number;
};

export type Region = 'global' | 'eu';

export const getRegion = (): Region => `${(process.env.NEXT_PUBLIC_REGION as Region) || 'global'}`; // Add template literal to avoid vite build time error, ref: https://main.vitejs.dev/guide/env-and-mode.html#production-replacement

export const getRegionData = (options?: regionDataOptions) => {
  const regionFromEnvironment = getRegion();
  const config: EnvironmentConfig = regionFromEnvironment === 'eu' ? europeConfig : globalConfig;

  if (options?.sosPrice) {
    const currencyLocale = options?.currencyLocale ?? (regionFromEnvironment === 'eu' ? 'id-ID' : 'en-US');
    const currency = options?.currency ?? (regionFromEnvironment === 'eu' ? 'EUR' : 'USD');
    const price = Math.round(options.sosPrice * Math.pow(10, 2)) / Math.pow(10, 2);
    config.sosPrice = new Intl.NumberFormat(currencyLocale, { style: 'currency', currency })
      .format(price)
      .replace('CA$', 'CAD$')
      .replace('A$', 'AUD$');
  }

  return config;
};
