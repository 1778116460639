import { z } from 'zod';

import { commonResponseSchema } from '@/services/schemas';
import { ajaxInstance, ajaxPath } from '@/services/utils';

import { rebootComputerKindSchema } from '../schemas';
import { RebootComputerKind } from '../types';

const getUrl = () => ajaxPath;

const rebootKindEnum = rebootComputerKindSchema.Enum;

const schema = z.object({
  success: z.boolean(),
  msg: z.string().optional(),
});
type ResponseData = z.infer<typeof schema>;

const service = (computerId: number, kind: RebootComputerKind) => {
  const kindValueMap = {
    [rebootKindEnum.restart_streamer]: '1',
    [rebootKindEnum.normal_reboot]: '2',
    [rebootKindEnum.safe_mode_reboot]: '3',
    [rebootKindEnum.shut_down]: '4',
    [rebootKindEnum.log_off]: '5',
  } as const;

  const payload = {
    type: 'notify_reboot',
    srs_id: computerId,
    opt: kindValueMap[kind],
  };

  return new Promise<ResponseData>((resolve, reject) => {
    ajaxInstance
      .post<ResponseData>(getUrl(), payload)
      .then((res) => {
        const { data } = res;

        if (data.success) {
          resolve(data);
        } else {
          reject({ statusCode: 9487, messages: [], errorReason: { error: data.msg } });
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : 9487;
        reject({ statusCode, messages: [], errorReason: {} });
      });
  });
};

const errorHandling = {
  noPermission: (response: any) => {
    const errorSchema = commonResponseSchema.merge(
      z.object({ result: z.literal(40403), data: z.object({ error: z.literal('team_permissions') }) }),
    );

    return errorSchema.safeParse(response).success;
  },
} as const;

/**
 * @deprecated
 */
export const rebootComputer_deprecated = {
  getUrl,
  service,
  errorHandling,
};
