import Link from 'next/link';
import { useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import Cookie from 'js-cookie';
import { Trans, useTranslation } from 'next-i18next';

import { RippleConfirmModal, RippleTypography } from '@/design';

const RESET_PASSWORD_URL = '/account_info/?change_password=1';

export const PasswordRotationAlert = () => {
  const { t } = useTranslation();
  const passwordRotationAlert = useDisclosure();
  const [digit, setDigit] = useState(0);
  const cleanCookie = () => {
    Cookie.remove('pcp_password_rotation_remaining_days');
  };

  const handleClose = () => {
    cleanCookie();
    passwordRotationAlert.onClose();
  };
  useEffect(() => {
    const dayCount = Cookie.get('pcp_password_rotation_remaining_days');
    if (dayCount && Number(dayCount)) {
      setDigit(Number(dayCount));
      passwordRotationAlert.onOpen();
    }
  }, []);
  return (
    <RippleConfirmModal
      confirmButtonText={t('common:ok')}
      isOpen={passwordRotationAlert.isOpen}
      onClose={handleClose}
      onConfirm={handleClose}
    >
      <RippleTypography variant="body02">
        {digit === 1 ? (
          <Trans t={t} i18nKey="common:passwordRotation" components={{ Link: <Link href={RESET_PASSWORD_URL} /> }} />
        ) : (
          <Trans t={t} i18nKey="common:passwordRotationDays" components={{ Link: <Link href={RESET_PASSWORD_URL} /> }} values={{ digit }} />
        )}
      </RippleTypography>
    </RippleConfirmModal>
  );
};
