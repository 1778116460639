import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import { TFunction } from 'next-i18next';

import { OTableSourceGroup } from '@/components/ObservableTable/types';
import type { SelectedRowIds } from '@/components/ObservableTable/useObservableSelection';
import { OTableGroupSortProps } from '@/components/ObservableTable/utils/groupSort';
import { getComputerIds, getComputerIdsInGroup, getComputerListById } from '@/services/computers';
import type {
  ComputerData,
  ComputerDataList,
  ComputerGroupData,
  ComputerListCustomizedField,
  FilterComputersOptions,
  GetComputerListData,
} from '@/services/computers';
import { ComputerGroup } from '@/services/group/types';
import { CHUNK_SIZE } from '@/utils/constants';

import type { RdsComputerHashTable, SelectorComputerData } from './types';

export const ALL_GROUP_ID = 'ALL_GROUP_ID';
export const DEFAULT_GROUP_ID = '0';
export const FROM_OTHER_GROUP_ID = 'null';
export const FROM_OTHER_GROUP_RAW = null;

export type GroupId = string;
export type ComputerId = string;
export type GroupHashMap = Record<GroupId, { data?: ComputerGroupData; list: Array<ComputerId> }>;

export const formatComputer = (computer: ComputerData): SelectorComputerData => {
  return {
    ...computer,
    id: String(computer.id),
    gid: String(computer.group_id),
  };
};

export type SelectorComputerGroup = Omit<ComputerGroupData, 'id'> & {
  id: string;
};

export const formatGroup = (group: ComputerGroupData | ComputerGroup): SelectorComputerGroup => {
  return {
    ...group,
    id: `${group.id ?? 'null'}`,
  };
};

export const filterDefaultGroup = (groupId: string) => groupId !== '0';
export const filterFromOtherGroup = (groupId: string) => groupId !== 'null';

export const getDefaultGroup = (t: TFunction): SelectorComputerGroup => ({
  id: DEFAULT_GROUP_ID,
  name: t('computer:defaultGroup'),
});

export const getFromOtherGroup = (t: TFunction): SelectorComputerGroup => ({
  id: FROM_OTHER_GROUP_ID,
  name: t('computer:selector.fromOtherGroup'),
});

// Sort Computer Groups:
// Default Group in last
// From Other Group in second last
export const sortComputerGroup = (groupA: OTableSourceGroup, groupB: OTableSourceGroup) => {
  if (groupA.id === DEFAULT_GROUP_ID && groupB.id === FROM_OTHER_GROUP_ID) {
    return 1;
  } else if (groupB.id === DEFAULT_GROUP_ID || groupB.id === FROM_OTHER_GROUP_ID) {
    return -1;
  }

  return 0;
};

export const sortComputerGroupWithSortBy: OTableGroupSortProps<OTableSourceGroup>['sortFunc'] = (sort, groupA, groupB) => {
  if (groupA.id === DEFAULT_GROUP_ID && groupB.id === FROM_OTHER_GROUP_ID) {
    return 1;
  } else if (groupB.id === DEFAULT_GROUP_ID || groupB.id === FROM_OTHER_GROUP_ID) {
    return -1;
  }

  const { accessor, desc } = sort;

  // TODO: FIXIT
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const targetA = `${groupA[accessor] ?? ''}`.toLocaleUpperCase();
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const targetB = `${groupB[accessor] ?? ''}`.toLocaleUpperCase();

  if (targetA > targetB) {
    return desc ? -1 : 1;
  } else if (targetB > targetA) {
    return desc ? 1 : -1;
  }

  return 0;
};

export function getAllComputerList(teamId: number, computerOptions?: FilterComputersOptions): Promise<ComputerDataList> {
  return getComputerIds(teamId).then((idList) => getComputerListInChunks(teamId, idList, computerOptions));
}

export function getAllComputerListInGroup(
  teamId: number,
  groupId: string,
  computerOptions?: FilterComputersOptions,
): Promise<ComputerDataList> {
  return getComputerIdsInGroup(teamId, groupId).then((idList) => getComputerListInChunks(teamId, idList, computerOptions));
}

async function getComputerListInChunks(
  teamId: number,
  idList: Array<number>,
  computerOptions?: FilterComputersOptions,
): Promise<ComputerDataList> {
  if (idList.length === 0) {
    return [];
  }

  const fields: Array<ComputerListCustomizedField> = ['group_id', 'version', 'online_status', 'rds_uuid'];

  const idListChunks = chunk(idList, CHUNK_SIZE);
  const requestList = idListChunks.map((idListChunk) => {
    return getComputerListById(teamId, idListChunk, 'customize', fields, computerOptions);
  });

  const result = await Promise.all(requestList);

  return flatten(result);
}

export function computeAggregateKey({ rds_uuid, group_id }: Pick<ComputerData, 'rds_uuid' | 'group_id'>): string {
  return `${rds_uuid ?? ''}-${group_id ?? ''}`;
}

export function aggregateRdsComputerRow(data: Array<SelectorComputerData>): Array<SelectorComputerData> {
  const aggregateRecord: Record<string, boolean> = {};
  return data.reduce<Array<SelectorComputerData>>((acc, cur) => {
    const aggregateTag = computeAggregateKey({ rds_uuid: cur.rds_uuid ?? '', group_id: cur.group_id });
    if (cur.rds_uuid === null || typeof cur.rds_uuid === 'undefined') {
      acc.push(cur);
      return acc;
    } else if (aggregateRecord[aggregateTag]) {
      return acc;
    } else {
      aggregateRecord[aggregateTag] = true;
      acc.push({ ...cur, id: aggregateTag });
      return acc;
    }
  }, []);
}

export function computeRdsComputerHashTable(data: Array<SelectorComputerData>): RdsComputerHashTable {
  return data.reduce<RdsComputerHashTable>((acc, cur) => {
    const aggregateTag = computeAggregateKey({ rds_uuid: cur.rds_uuid ?? '', group_id: cur.group_id });
    if (cur.rds_uuid === null || typeof cur.rds_uuid === 'undefined') {
      return acc;
    } else if (Array.isArray(acc[aggregateTag])) {
      acc[aggregateTag].push(cur.id);
      return acc;
    } else {
      acc[aggregateTag] = [cur.id];
      return acc;
    }
  }, {});
}

export function segregateSelectedRdsComputer(selectedIds: SelectedRowIds, rdsComputerHashTable: RdsComputerHashTable): SelectedRowIds {
  const segregatedSelectedIdList: Array<string> = Object.entries(selectedIds).reduce<Array<string>>((acc, [computerId, selected]) => {
    if (selected) {
      const rdsComputerIdList = rdsComputerHashTable[computerId];

      if (rdsComputerIdList) {
        acc.push(...rdsComputerIdList);
      } else {
        acc.push(computerId);
      }
      return acc;
    }
    return acc;
  }, []);

  return segregatedSelectedIdList.reduce<SelectedRowIds>((acc, computerId) => {
    acc[computerId] = true;
    return acc;
  }, {});
}

export async function getSelectedComputerListWithRDS(
  teamId: number,
  selectedComputerData: GetComputerListData,
): Promise<GetComputerListData> {
  const groups = selectedComputerData.groups;
  let computers = selectedComputerData.computers;

  if (computers.length === 0) {
    // Should immediately return result if the selected computer list is empty
    // If pass empty array to `getComputerListById`, will get all computer list
    return { computers, groups };
  }

  // TODO: This is a temporary solution, Should refactor ComputerSelector that no need to fetch computer list API here
  const selectedComputerIdList: Array<number> = computers.map(({ id }) => id);
  const selectedComputerListWithRDS = await getComputerListById(teamId, selectedComputerIdList, 'customize', [
    'id',
    'group_id',
    'rds_uuid',
  ]);
  computers = selectedComputerListWithRDS;
  return { computers, groups };
}
