import { useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import {
  RippleArrowDown16,
  RippleArrowUp16,
  RippleButton,
  RippleMenu,
  RippleMenuButton,
  RippleMenuGroup,
  RippleMenuList,
  RippleSortOptionAsc16,
  RippleSortOptionDesc16,
  RippleTypography,
  SortByStates,
} from '..';

export type RippleStickySortingMenuProps = {
  sortList: Array<{
    id: string;
    name: string;
    sortBy: SortByStates;
  }>;
  onSortBy: (value: { id: string; sortBy: Exclude<SortByStates, false> }) => void;
  children: string;
};

export const RippleStickySortingMenu = (props: RippleStickySortingMenuProps) => {
  const { sortList, onSortBy, children } = props;

  return (
    <RippleMenu closeOnBlur placement="right-start">
      {({ isOpen }) => {
        return (
          <>
            <RippleMenuButton
              aria-label="sort by"
              data-testid="filter-button"
              borderRadius="4px"
              padding="1px 2px 1px 4px"
              backgroundColor={isOpen ? 'blue.20' : 'transparent'}
              _hover={{ bg: 'blue.10' }}
            >
              <RippleTypography as="span" variant="heading09" color={isOpen ? 'blue.200' : 'neutral.300'}>
                {children}
              </RippleTypography>
              {isOpen ? <RippleArrowUp16 color="blue.200" /> : <RippleArrowDown16 />}
            </RippleMenuButton>
            <RippleMenuList width="336px" zIndex={10}>
              <RippleMenuGroup title="Sort by" textAlign="left" color="neutral.100">
                {sortList.map((sort) => {
                  return (
                    <Flex key={sort.id} width="100%" justifyContent="space-between" alignItems="center" p="4px 12px">
                      <RippleTypography
                        variant="body02"
                        color="dark.100"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        title={sort.name}
                      >
                        {sort.name}
                      </RippleTypography>
                      <Box flexShrink={0}>
                        <AscButton sortBy={sort.sortBy} onClick={() => onSortBy({ id: sort.id, sortBy: 'asc' })} />
                        <DescButton sortBy={sort.sortBy} onClick={() => onSortBy({ id: sort.id, sortBy: 'desc' })} />
                      </Box>
                    </Flex>
                  );
                })}
              </RippleMenuGroup>
            </RippleMenuList>
          </>
        );
      }}
    </RippleMenu>
  );
};

const AscButton = ({ sortBy, onClick }: { sortBy: SortByStates; onClick: () => void }) => {
  const { t } = useTranslation();
  const [hoverColor, setHoverColor] = useState<string | null>(null);
  const handleMouseEnter = () => {
    setHoverColor('white');
  };
  const handleMouseLeave = () => {
    setHoverColor(null);
  };

  return (
    <RippleButton
      px="12px"
      height="24px"
      border={0}
      backgroundColor={sortBy === 'asc' ? 'blue.20' : 'white'}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex alignItems="center">
        <RippleSortOptionAsc16 color={hoverColor ?? (sortBy === 'asc' ? 'blue.200' : 'neutral.300')} />
        <RippleTypography variant="body02" ml="4px" color={hoverColor ?? (sortBy === 'asc' ? 'blue.200' : 'neutral.300')}>
          {t('common:asc')}
        </RippleTypography>
      </Flex>
    </RippleButton>
  );
};

const DescButton = ({ sortBy, onClick }: { sortBy: SortByStates; onClick: () => void }) => {
  const { t } = useTranslation();
  const [hoverColor, setHoverColor] = useState<string | null>(null);
  const handleMouseEnter = () => {
    setHoverColor('white');
  };
  const handleMouseLeave = () => {
    setHoverColor(null);
  };

  return (
    <RippleButton
      ml="4px"
      px="12px"
      height="24px"
      border={0}
      backgroundColor={sortBy === 'desc' ? 'blue.20' : 'white'}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Flex alignItems="center">
        <RippleSortOptionDesc16 color={hoverColor ?? (sortBy === 'desc' ? 'blue.200' : 'neutral.300')} />
        <RippleTypography variant="body02" ml="4px" color={hoverColor ?? (sortBy === 'desc' ? 'blue.200' : 'neutral.300')}>
          {t('common:desc')}
        </RippleTypography>
      </Flex>
    </RippleButton>
  );
};
