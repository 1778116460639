import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleTechnician({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M15.5357 12.7081L19.3305 16.5029C19.8018 16.9743 20.4617 18.2 19.3305 19.3313C18.1992 20.4626 16.9737 19.8024 16.5024 19.331L13.7682 16.5968"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.9647 7.79322C11.7799 6.54183 11.0698 5.41256 10.4924 4.83511C9.43172 3.77445 8.37106 3.4209 6.6033 3.77445L6.26838 4.11004L8.37106 6.24932C8.37106 6.24932 9.43172 7.30998 8.37106 8.37064C7.3104 9.4313 6.24974 8.37064 6.24974 8.37064L4.14706 6.23136C4.12876 6.24966 3.77562 6.60363 3.77562 6.60363C3.42132 8.37064 3.77496 9.43121 4.83553 10.492C5.14864 10.8051 5.62401 11.1573 6.19315 11.4435"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75216 19.3368C5.88342 20.468 7.10865 19.8076 7.5801 19.3363L12.5302 14.3869L9.7019 11.5586L4.75216 16.5083C4.28068 16.9797 3.6209 18.2055 4.75216 19.3368Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M11.1154 12.9723L16.7723 7.31543" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M18.54 4.13299L19.2471 4.8401L19.9546 5.54755L18.5404 7.66887L16.7726 7.31532L16.4191 5.54755L18.54 4.13299Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
