export const LS_KEY_PCP_ACCESS_TOKEN_EXP = 'pcp_access_token_exp';
export const LS_KEY_PCP_DEV_UUID = 'pcp_dev_uuid';
export const LS_KEY_PCP_REFRESH_TOKEN = 'pcp_refresh_token';
export const LS_KEY_PCP_REFRESH_TOKEN_EXP = 'pcp_refresh_token_exp';
export const LS_KEY_PCP_VALIDATION_TOKEN = 'pcp_validation_token';
export const LS_KEY_PCP_CSRF_TOKEN = 'pcp_csrf_token';

export const COOKIE_KEY_PCP_USED_TEAM_AT = 'pcp_used_team_at';
export const COOKIE_KEY_PCP_SOS_TEAM_ID = 'pcp_sos_team_id';
export const COOKIE_KEY_PCP_STA_TEAM_ID = 'pcp_sta_team_id';
export const COOKIE_KEY_PCP_STB_TEAM_ID = 'pcp_stb_team_id';
