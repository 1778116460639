import { Flex, forwardRef, useMenuContext, useMultiStyleConfig } from '@chakra-ui/react';

import { featureControl } from '@/feature/toggle';

import { RippleButton, RippleButtonProps } from '../RippleButton';
import { RippleCount } from '../RippleCount';
import { RippleArrowDown16, RippleArrowUp16 } from '../RippleIcon';
import { RippleStrong } from '../RippleStrong';

type CountProps = { isShowCount?: boolean; count?: number };
type RippleSelectButtonProps = RippleButtonProps & { isOpen: boolean } & CountProps;
export const RippleSelectButton = forwardRef<RippleSelectButtonProps, 'button'>(
  ({ isOpen, children, variant, size, isShowCount, count, isDisabled, ...otherProps }, ref: React.ForwardedRef<any>) => {
    const styles = useMultiStyleConfig('rippleSelect', { variant, size });

    return (
      <RippleButton ref={ref} isActive={isOpen} isDisabled={isDisabled} {...otherProps} __css={styles.button}>
        <Flex justifyContent="space-between" alignItems="center">
          <RippleStrong as="div" variant="strong02" color="inherit" isTruncated flex={1} textAlign="left">
            {children}
          </RippleStrong>
          {isShowCount && count && <RippleCount count={count} variant={isDisabled ? 'neutralLight' : 'deepBlue'} sx={{ mx: '4px' }} />}
          {isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
        </Flex>
      </RippleButton>
    );
  },
);

type RippleSelectButtonWithContextProps = Omit<RippleSelectButtonProps, 'isOpen'>;

const SelectButtonWithContext = forwardRef<RippleSelectButtonWithContextProps, 'button'>((props, ref: React.ForwardedRef<any>) => {
  const { isOpen } = useMenuContext();

  return <RippleSelectButton ref={ref} isOpen={isOpen} {...props} />;
});

const SelectButtonWithContext_deprecated = forwardRef<RippleSelectButtonWithContextProps, 'button'>(
  (
    { children, variant, size, isShowCount, count, isDisabled, ...otherProps }: RippleSelectButtonWithContextProps,
    ref: React.ForwardedRef<any>,
  ) => {
    const { isOpen } = useMenuContext();
    const styles = useMultiStyleConfig('rippleSelect', { variant, size });

    return (
      <RippleButton ref={ref} isActive={isOpen} isDisabled={isDisabled} {...otherProps} __css={styles.button}>
        <Flex justifyContent="space-between" alignItems="center">
          <RippleStrong as="div" variant="strong02" color="inherit" isTruncated flex={1} textAlign="left">
            {children}
          </RippleStrong>
          {isShowCount && count && <RippleCount count={count} variant={isDisabled ? 'neutralLight' : 'deepBlue'} sx={{ mx: '4px' }} />}
          {isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
        </Flex>
      </RippleButton>
    );
  },
);

export const RippleSelectButtonWithContext = featureControl.getToggle('PCP_1957__RippleSelect_refactor')
  ? SelectButtonWithContext
  : SelectButtonWithContext_deprecated;
