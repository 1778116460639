import { ReactNode, useMemo } from 'react';

import { Flex, ToastPosition, useToast } from '@chakra-ui/react';

import { RippleAlertSpecificColor, RippleClear, RippleClose, RippleInfoFilled, RippleReady } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleTypography } from '../RippleTypography';

export type FlashMessageVariant = 'info' | 'success' | 'alert' | 'error';

const VARIANT_MAP_ICON = {
  info: <RippleInfoFilled color="blue.80" />,
  success: <RippleReady color="green.100" />,
  alert: <RippleAlertSpecificColor />,
  error: <RippleClear color="red.100" />,
};

type FlexMessageBoxProps = {
  variant: FlashMessageVariant;
  onClose: () => void;
  children: ReactNode;
};

const FlexMessageBox = ({ variant, onClose, children }: FlexMessageBoxProps) => {
  const icon = VARIANT_MAP_ICON[variant];

  return (
    <Flex
      p="8px"
      bg="dark.90"
      border="1px solid"
      borderColor="dark.60"
      borderRadius="4px"
      color="white"
      boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
      maxW="352px"
      columnGap="8px"
    >
      <Flex columnGap="8px" p="4px 0 4px 8px" flex={1}>
        {icon}
        <RippleTypography variant="body02" py="2px">
          {children}
        </RippleTypography>
      </Flex>
      <RippleIconButton aria-label="close" icon={<RippleClose />} onClick={onClose} />
    </Flex>
  );
};

type RippleFlashMessageOptions = {
  title: ReactNode;
  variant: FlashMessageVariant;
  id?: string;
  position?: ToastPosition;
};

export const useRippleFlashMessage = () => {
  const toast = useToast();

  return useMemo(() => {
    return {
      isFlashMessageActive: toast.isActive,
      flashMessage: (option: RippleFlashMessageOptions) => {
        const { id, variant, title, position } = option;

        toast({
          id,
          position: position ?? 'bottom',
          duration: 5000,
          render: ({ onClose }) => {
            return (
              <FlexMessageBox variant={variant} onClose={onClose}>
                {title}
              </FlexMessageBox>
            );
          },
        });
      },
      closeAll: toast.closeAll,
    };
  }, [toast]);
};
