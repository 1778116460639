import { Icon } from '@chakra-ui/react';

import { DEFAULT_ICON_COLOR } from './constants';
import type { RippleIconProps } from './types';

export function RippleRemoteControl({ color = DEFAULT_ICON_COLOR }: RippleIconProps) {
  return (
    <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color={color}>
      <path
        d="M21 11V6C21 4.89543 20.1046 4 19 4H5C3.89543 4 3 4.89543 3 6V18C3 19.1046 3.89543 20 5 20H10.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20.2046 21.843L20.7443 21.3838C21.0424 21.1302 21.0862 20.6589 20.8422 20.3311L17.341 15.6285L19.9422 14.7821C20.2945 14.6674 20.3325 14.1489 20.0027 13.9563L11.611 9.05715C11.2854 8.86702 10.9165 9.18086 11.0168 9.56279L13.5997 19.4042C13.7012 19.791 14.1824 19.8678 14.366 19.5265L15.7217 17.006L19.2229 21.7087C19.467 22.0365 19.9065 22.0967 20.2046 21.843Z"
        fill="currentColor"
      />
    </Icon>
  );
}
