import { ReactNode, useMemo } from 'react';

import { Box, Flex, useToast } from '@chakra-ui/react';

import { RippleButton, RippleButtonProps } from '../RippleButton';
import { RippleAlertSpecificColor, RippleClear, RippleClose, RippleInfoFilled, RippleReady } from '../RippleIcon';
import { RippleIconButton } from '../RippleIconButton';
import { RippleStrong } from '../RippleStrong';

export type NotificationVariant = 'info' | 'success' | 'alert' | 'error';

const VARIANT_MAP_ICON = {
  info: <RippleInfoFilled color="blue.80" />,
  success: <RippleReady color="green.100" />,
  alert: <RippleAlertSpecificColor />,
  error: <RippleClear color="red.100" />,
};

type NotificationProps = {
  variant: NotificationVariant;
  onClose?: () => void;
  title: ReactNode;
  children: ReactNode;
};

const Notification = ({ variant, onClose, title, children }: NotificationProps) => {
  const icon = VARIANT_MAP_ICON[variant];

  return (
    <Flex
      p="8px"
      bg="dark.90"
      border="1px solid"
      borderColor="dark.60"
      borderRadius="4px"
      color="white"
      boxShadow="0px 8px 16px 4px rgba(0, 0, 0, 0.12)"
      maxW="376px"
      direction="column"
      columnGap="8px"
    >
      <Flex columnGap="8px" w="100%">
        <Flex columnGap="8px" p="4px 0 4px 8px" flex={1}>
          {icon}
          <RippleStrong as="p" variant="strong01" color="white">
            {title}
          </RippleStrong>
        </Flex>
        {onClose && <RippleIconButton aria-label="close" icon={<RippleClose />} onClick={onClose} />}
      </Flex>
      <Box ml="40px">{children}</Box>
    </Flex>
  );
};

type RippleFlashMessageOptions = {
  title: ReactNode;
  variant: NotificationVariant;
  id?: string;
  /**
   * Main content
   */
  content?: ReactNode;
  /**
   * primary button on the bottom-right of Notification
   */
  primaryAction?: RippleButtonProps & { label: string };
  /**
   * Other buttons exclude primary button, will order from right to left
   */
  secondaryActions?: Array<RippleButtonProps & { label: string }>;
  /**
   * Should show close button on the top-right or not
   */
  isClosable?: boolean;
};

export const useRippleNotification = () => {
  const toast = useToast();

  return useMemo(() => {
    return {
      isNotificationActive: toast.isActive,
      openNotification: (option: RippleFlashMessageOptions) => {
        const { id, variant, title, content, primaryAction, secondaryActions, isClosable = true } = option;

        toast({
          id,
          position: 'top-right',
          duration: null,
          render: ({ onClose }) => {
            return (
              <Notification variant={variant} onClose={isClosable ? onClose : undefined} title={title}>
                {content}
                <Flex p="8px" columnGap="10px" direction="row-reverse">
                  {primaryAction && (
                    <RippleButton {...primaryAction} size="xs">
                      {primaryAction.label}
                    </RippleButton>
                  )}
                  {secondaryActions &&
                    secondaryActions.map((action) => (
                      <RippleButton key={action.label} {...action} size="xs">
                        {action.label}
                      </RippleButton>
                    ))}
                </Flex>
              </Notification>
            );
          },
        });
      },
      closeAll: toast.closeAll,
    };
  }, [toast]);
};
