import React from 'react';

import { Flex, Portal, SystemStyleObject, forwardRef, useControllableState, useFormControl, useMultiStyleConfig } from '@chakra-ui/react';
import type { MenuProps, StyleProps, UseFormControlProps } from '@chakra-ui/react';

import { RippleMenu, RippleMenuButton, RippleMenuDivider, RippleMenuGroup, RippleMenuList } from '../RippleMenu';
import { RippleTypography } from '../RippleTypography';
import { RippleButtonProps } from './../RippleButton';
import { RippleSelectContextProvider, RippleSelectLabel, RippleSelectOption } from './RippleSelect';
import type { RippleSelectOptionProps } from './RippleSelect';
import { RippleSelectButtonWithContext } from './RippleSelectButton';

export type RippleSingleSelectProps<T extends string = string> = Omit<RippleButtonProps, 'onChange' | 'placeholder' | 'variant'> & {
  children: React.ReactNode;
  value: T | undefined;
  placeholder: React.ReactNode;
  onChange: (value: T | undefined) => void;
  variant?: 'border' | 'borderless';
  size?: 'lg' | 'md' | 'sm' | 'xs';
  helperText?: string;
  boxSx?: SystemStyleObject;
  menuListSx?: SystemStyleObject;
  menuListProps?: {
    appendUnderPortal?: boolean; // Whether the menu list should be appended to the portal. To Prevent Select is under container with overflow: 'hidden'
  };
  menuProps?: {
    isOpen?: MenuProps['isOpen'];
    placement?: MenuProps['placement'];
  };
  /**
   * The options' max width when
   * @note Discussed with the design team, currently we don't have a dropdown like component. So we modified the select achieve the same result.
   */
  optionMaxWidth?: StyleProps['width'];
} & StyleProps &
  UseFormControlProps<HTMLButtonElement>;

function RippleSingleSelectCore(props: RippleSingleSelectProps, ref: React.ForwardedRef<any>) {
  const {
    children,
    value,
    placeholder,
    onChange,
    variant = 'border',
    size = 'sm',
    helperText,
    boxSx: _boxSx, // shouldn't pass into RippleMenuButton
    menuListSx,
    menuListProps,
    menuProps: _, // shouldn't pass into RippleMenuButton
    optionMaxWidth,
    ...menuButtonProps
  } = props;

  const { menuListProps: _menuListProps, menuProps, boxSx, ...formControlProps } = props;

  const styles = useMultiStyleConfig('rippleSelect', { variant, size });

  const formProps = useFormControl<HTMLButtonElement>(formControlProps);
  const { id: _id, onFocus: _onFocus, onBlur: _onBlur, ...helperTextProps } = formProps;

  const MenuList = (
    <RippleMenuList maxWidth={optionMaxWidth ?? '100%'} zIndex="dropdown" position="relative" sx={menuListSx}>
      {children}
    </RippleMenuList>
  );

  return (
    <RippleSelectContextProvider type="single" value={value} onChange={onChange}>
      <Flex direction="column" sx={boxSx}>
        <RippleMenu
          closeOnSelect
          closeOnBlur
          matchWidth={typeof optionMaxWidth === 'undefined'}
          isOpen={menuProps?.isOpen}
          placement={menuProps?.placement ?? 'bottom'}
        >
          <RippleMenuButton ref={ref} as={RippleSelectButtonWithContext} {...formProps} {...menuButtonProps}>
            <RippleSelectLabel placeholder={placeholder} />
          </RippleMenuButton>

          {menuListProps?.appendUnderPortal ? <Portal>{MenuList}</Portal> : MenuList}
        </RippleMenu>

        {helperText && (
          <RippleTypography variant="body03" __css={styles.helperText} {...helperTextProps}>
            {helperText}
          </RippleTypography>
        )}
      </Flex>
    </RippleSelectContextProvider>
  );
}

export const RippleSingleSelect = forwardRef(RippleSingleSelectCore) as <T extends string>(
  props: RippleSingleSelectProps<T> & { ref?: React.ForwardedRef<any> },
) => React.JSX.Element;

export type RippleSingleSelectOptionProps = RippleSelectOptionProps;
export const RippleSingleSelectOption = RippleSelectOption;

export const RippleSingleSelectOptionGroup = RippleMenuGroup;

export const RippleSingleSelectOptionDivider = RippleMenuDivider;

export type UseRippleSingleSelectOptions<T extends string> = {
  defaultValue?: T | undefined;
  onChange?: (value: T | undefined) => void;
};

export type UseRippleSingleSelectReturn<T extends string> = {
  value: T | undefined;
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>;
  selectProps: {
    value: T | undefined;
    onChange: (value: T | undefined) => void;
  };
};

export function useRippleSingleSelect<T extends string = string>({
  defaultValue,
  onChange,
}: UseRippleSingleSelectOptions<T> = {}): UseRippleSingleSelectReturn<T> {
  const [value, setValue] = useControllableState<T | undefined>({
    defaultValue,
    onChange,
  });

  return {
    value,
    setValue,
    selectProps: {
      value,
      onChange: setValue,
    },
  };
}
